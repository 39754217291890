import { useTasksStore } from "~/store/tasks";

export function useDownloadTask() {
  const tasksStore = useTasksStore();

  watch(
    () => tasksStore.dataEvent,
    async (event) => {
      if (event) {
        // check if the event is for the current task
        if (event.task_id !== tasksStore.taskId) return;

        tasksStore.isProcessing = true;
        tasksStore.processedPercent = event?.processed;

        // when the task is done, download the file
        if (event?.processed === 100 && event?.url && event?.filename && event?.content_type !== "application/json") {
          useIsLoading(true);
          await tasksStore.downloadTask(event?.url, event?.filename);
          useIsLoading(false);

          tasksStore.isProcessing = false;
          tasksStore.taskId = null;
          tasksStore.processedPercent = null;
        }

        return;
      }

      tasksStore.isError = true;
    }
  );
}
